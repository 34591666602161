<template>
    <div class="p-relative">
        <MeetingForm />

        <v-btn block @click="cscAnswerOpenDlg">
            Добавить исполнение к заявке
        </v-btn>
        <!--
            getHref
            getHrefNoToken
        -->
        <a
            :href="getHref()"
            target="_blank"
        >
            К архиву!ӘӘӘӘ
        </a>
        <v-btn block @click="call_awesome_notify">
            Awesome notify
        </v-btn>


        <v-btn block @click="callNIC_Interface">
            Вызвать подписание NCA Layer
        </v-btn>

        <v-divider></v-divider>

        <v-btn block @click="getSignalRTokens">
            Получить токены SignalR            
        </v-btn>

        <div v-if="tokensResponse">
            {{ tokensResponse.data.payload }}
        </div>

        <v-divider></v-divider>

        <v-btn block @click="onGovRegDlg">
            Вызвать окно гос. регистрации
        </v-btn>

        <v-divider></v-divider>

        <v-btn block @click="onFirstСonsiderСhangeDlg()">
            Вызвать окно изменения первого рассматривающего 
        </v-btn>
        <v-btn block @click="sendForExecutionDlg()">
            Отправка_на_исполнение
        </v-btn>
        <v-divider></v-divider>

        <div v-for="[key, value] of Object.entries(statuses)" :key="key" >
            <StatusChip :status="value" />
            
            {{ JSON.stringify(statuses[key].style) }}

        </div>

        <v-divider></v-divider>

        <div>

            <v-btn block @click="onBadRequest">
                Bad Request
            </v-btn>

            <v-btn block @click="onNotFound">
                NotFound
            </v-btn>

            <v-btn block @click="onUnauthorized">
                Unauthorized
            </v-btn>

        </div>

        <div>
            REFERENCES: {{refs}}
            <v-divider></v-divider>
            <br/>
            <v-text-field label="Код_справочника" v-model="refId"></v-text-field>
            <v-btn block :disabled="!refId" @click="printRef">
                Получить справочник
            </v-btn>

            <br/>
            WORKPLACES: {{workplaces}}
            <v-divider></v-divider>
            <br/>
            <v-btn block @click="printWorkplaces">
                Получить список сотрудников
            </v-btn>

            <br/>
            SIGNERS: {{signers}}
            <v-divider></v-divider>
            <br/>
            <v-btn block @click="printSigners">
                Получить список подписантов
            </v-btn>
            
            <br/>
            <v-divider></v-divider>
            <br/>
            <v-btn block @click="showTime">
                Сервер, который сейчас час?
            </v-btn>
        </div>
        <Calendar :is-widget="false" />
     
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { httpAPI } from "@/api/httpAPI";
import StatusChip from '@/components/StatusChip';
import Calendar from '@/components/Calendar';
import MeetingForm from '@/views/EventsManagment/MeetingCard.vue';

export default {
    name: "Sandbox",
    components:{
        StatusChip,
        Calendar,
        MeetingForm,
    },
    data: () => ({
        refId: null,
        tokensResponse: null,
    }),
    computed: {
        ...mapGetters('references', { refs: 'GetReferences', workplaces: 'GetWorkplaces', signers: 'GetSigners', statuses: 'GetAvaibleStatuses' }),
        ...mapGetters('auth', ['getUserInfo']),
    },
    methods:{
        ...mapActions('references', ['getReference', 'getWorkplaces', 'getSigners', 'getTimestamp']),
        async printRef() {
            let ref = await this.getReference({ id: this.refId });
            console.log(ref);
        },
        async printWorkplaces() {
            let workplaces = await this.getWorkplaces();
            console.log(workplaces);
        },
        async printSigners() {
            let signers = await this.getSigners();
            console.log(signers);
        },
        async showTime() {
            await this.getTimestamp();
        },
        async onBadRequest() {
            
            let result = await httpAPI({
                url: '/api/references/badrequest'
            });
            
            console.log(result);
        },
        async onNotFound() {
            await httpAPI({
                url: '/api/references/notfound'
            });
        },
        async onUnauthorized() {
            await httpAPI({
                url: '/api/references/unauthorized'
            });
        },
        call_awesome_notify(){
            let msg = '';
            this.$notify.success(msg != '' ? msg : 'Действие_успешно_выполнено');
        },
        async callNIC_Interface() {
            try
            {
                let result = await this.$store.dispatch(
                    'signing/signDocumentNIC',
                    {
                        data: 'TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGludWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4='
                    }
                );

                console.log(result);
            }
            catch(ex)
            {
                console.log(ex);
            }
        },
        async getSignalRTokens() {
            let response = await httpAPI({
                url: '/api/auth/getSignalRTokens'
            });

            if (response)
                this.tokensResponse = response;
        },
        async onGovRegDlg() {
            try
            {
                let eecbMeta = await this.$store.dispatch('dialogs/govermentRegistration/open', null, { root: true });
                console.log(eecbMeta);
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        getHref()
        {
            return `http://localhost:6798/Home/Hello?token=${this.$store.getters['auth/getUserInfo']?.token}`;
        },
        getHrefNoToken()
        {
            return `http://localhost:6798/Home/Hello`;
        },
        async cscAnswerOpenDlg(){
            await this.$store.dispatch('dialogs/cscAnswer/open', null, { root: true });
        },
        async onFirstСonsiderСhangeDlg() {
            try {
                await this.$store.dispatch('dialogs/firstConsiderChange/open', null, { root: true })
            }
            catch(ex) { return; }
        },
        async sendForExecutionDlg(){
            try {
                await this.$store.dispatch('dialogs/sendForExecution/open', null, { root: true })
            }
            catch(ex) { return; }
        }
    }
}
</script>